<template>
  <b-container id="documents">
    <masthead
      title="Documents"
      :filter-options="filterOptions"
      :should-display-date-range="true"
      @changed="searchChanged"
    >
      <!-- TODO: remove style on document upload button once connected to the upload endpoint -->
      <b-button
        v-b-modal.document-upload-modal
        variant="primary"
        aria-label="Upload Document button"
        class="action-btn upload-btn"
        :class="{'btn-shadow mr-3 rounded': !hasSmallScreen}"
      >
        <feather-icon type="upload" />
        <span v-if="!hasSmallScreen">
          Upload Document
        </span>
        <span v-else>
          Upload
        </span>
      </b-button>
      <b-modal
        id="document-upload-modal"
        ref="documentUpload"
        size="lg"
        hide-footer
        centered
        @close="resetModal"
        @hide="resetModal"
      >
        <b-alert
          v-model="showModalErrorAlert"
          variant="danger"
          dismissible
          @dismissed="resetErrorAlert"
        >
          <p>An error has occurred when attempting to upload your document.</p>
        </b-alert>
        <b-container>
          <b-row class="justify-content-center">
            <h5 class="modal-title">Upload Document</h5>
          </b-row>
          <b-row class="mb-4">
            <b-form-file
              v-model="uploadedDocument"
              accept=".pdf"
              drop-placeholder="Drag document here..."
            />
          </b-row>
          <b-row class="justify-content-center">
            <span class="mb-2">
              Select a company
            </span>
          </b-row>
          <b-row>
            <b-form-select
              v-model="companyUploadSelection"
              :options="selectData"
              @change="companySelected"
            />
          </b-row>
          <b-row>
            <br>
          </b-row>
          <b-row>
            <b-col>
              <b-button
                class="float-right"
                aria-label="Cancel button"
                @click="cancel"
              >
                Cancel
              </b-button>
            </b-col>
            <b-button
              class="float-right confirm-btn"
              variant="primary"
              aria-label="Confirm button"
              :disabled="confirmDisabled"
              @click="confirmUpload"
            >
              Confirm
            </b-button>
          </b-row>
        </b-container>
      </b-modal>
      <b-button
        variant="primary"
        class="mr-3 action-btn"
        :aria-label="downloadBtnText + ' button'"
        :disabled="downloadBtnDisabled"
        @click="downloadSelection"
      >
        <feather-icon type="download" />
        {{ downloadBtnText }}
      </b-button>
      <b-button
        v-if="documentRequestsEnabledOnWebsite"
        v-b-tooltip
        :title="disabledReason"
        variant="primary"
        class="float-right action-btn"
        aria-label="Bundle Multiple Docs button"
        :disabled="requestMultipleBtnDisabled"
        @click="requestSelection"
      >
        Bundle Multiple Docs
      </b-button>
    </masthead>

    <b-tabs v-model="tabIndex">
      <b-tab>
        <template slot="title">
          My Documents
        </template>

        <ajax-table
          ref="myDocuments"
          select-mode="multi"
          :selectable="true"
          :table-definition="myDocuments"
          :selectable-function="selectableFunction"
          @selection-changed="selectionEvent"
          @loaded="onLoad"
        >
          <template slot="head(approvedAt)" slot-scope="data">
            <span id="`t2-${data}-approvedAt`" :data="data">Received</span>
          </template>
          <template v-if="documentRequestsEnabledOnWebsite" slot="head(requestDocument)" slot-scope="data">
            <span id="`t2-${data}-requestDocument`" :data="data">Request Document</span>
          </template>
          <template slot="cell(status)" slot-scope="row">
            {{ capitalize(row.item.status) }}
          </template>
          <template slot="cell(jurisdiction)" slot-scope="data">
            {{ data.item.jurisdiction }}
          </template>
          <template slot="cell(type)" slot-scope="data">
            {{ data.item.type }}
            <div class="text-muted type-subtitle">
              <span>{{ data.item?.vehicle_year }}</span>
              <span>{{ data.item?.vehicle_make }}</span>
              <span>{{ data.item?.vehicle_model }}</span>
              <span>{{ data.item?.vin___hin }}</span>
            </div>
          </template>
          <template slot="cell(action)" slot-scope="row">
            <b-button
              variant="link"
              class="mr-1 pt-0"
              :aria-label="row.item.payment_locked ? 'Unlock Document button' : 'View Document button'"
              @click="view(row.item)"
            >
              <feather-icon
                v-if="row.item.payment_locked"
                type="lock"
                width="18"
                height="18"
                class="doc-lock-icon-margin"
              />
              {{ row.item.payment_locked ? 'Unlock' : 'View' }}
            </b-button>
          </template>
          <template v-if="documentRequestsEnabledOnWebsite"
                    slot="cell(requestDocument)"
                    slot-scope="row"
          >
            <div v-if="row.item.document_can_be_requested">
              <b-button
                variant="link"
                class="mr-1 pt-0"
                aria-label="Click to Request button"
                @click="request(row.item.id, row.item.company_id)"
              >
                Click to Request
              </b-button>
            </div>
            <div v-else-if="row.item.document_request_status != ''">
              <div v-if="row.item.document_request_status == 'pending_payment'">
                <b-button
                  variant="link"
                  class="mr-1 pt-0"
                  aria-label="Pending payment button"
                  @click="viewRequest(row.item.id)"
                >
                  Pending Payment
                </b-button>
              </div>
              <div v-else-if="capitalize(row.item.document_request_status) == 'Completed' && row.item.document_tracking_number">
                <b-button
                  variant="link"
                  class="mr-1 pt-0"
                  :aria-label="capitalize(row.item.document_request_status) + ' button'"
                  @click="viewRequest(row.item.id)"
                >
                  {{ capitalize(row.item.document_request_status) }}
                </b-button>

                <div style="display:inline-flex">
                  -
                  <a class="ml-1 track-link" @click="trackShipping(row.item.document_tracking_url)">
                    Track
                  </a>
                </div>
              </div>
              <div v-else-if="row.item.document_request_status == 'rejected'">
                Document Unavailable
              </div>
              <div v-else>
                <b-button
                  variant="link"
                  class="mr-1 pt-0"
                  :aria-label="capitalize(row.item.document_request_status) + ' button'"
                  @click="viewRequest(row.item.id)"
                >
                  {{ capitalize(row.item.document_request_status) }}
                </b-button>
              </div>
            </div>
          </template>
        </ajax-table>
      </b-tab>

      <b-tab>
        <template slot="title">
          Documents Shared With Me
        </template>
        <ajax-table
          select-mode="multi"
          :selectable="true"
          :table-definition="documentsSharedWithMe"
          @selection-changed="selectionEvent"
          @loaded="onSharedLoad"
        >
          <template slot="head(approvedAt)" slot-scope="data">
            <span id="`${data}-approvedAt`" :data="data">Received</span>
          </template>
          <template slot="cell(status)" slot-scope="row">
            {{ capitalize(row.item.status) }}
          </template>
          <template slot="cell(jurisdiction)" slot-scope="data">
            {{ data.item.jurisdiction }}
          </template>
          <template slot="cell(type)" slot-scope="data">
            {{ data.item.type }}
            <div class="text-muted type-subtitle">
              <span>{{ data.item?.vehicle_year }}</span>
              <span>{{ data.item?.vehicle_make }}</span>
              <span>{{ data.item?.vehicle_model }}</span>
              <span>{{ data.item?.vin___hin }}</span>
            </div>
          </template>
          <template slot="cell(action)" slot-scope="row">
            <b-button
              variant="link"
              class="mr-1 pt-0"
              :aria-label="row.item.payment_locked ? 'Unlock Document button' : 'View Document button'"
              @click="view(row.item)"
            >
              <feather-icon
                v-if="row.item.payment_locked"
                type="lock"
                width="18"
                height="18"
                class="doc-lock-icon-margin"
              />
              {{ row.item.payment_locked ? 'Unlock' : 'View' }}
            </b-button>
          </template>
        </ajax-table>
      </b-tab>
    </b-tabs>

    <doc-lock-modal :document="viewedDocument" :bus="bus" />
    <pay-invoices :bus="bus" @completed="paymentCompleted" />
    <doc-lock-pay-modal :bus="bus" @completed="paymentCompletedDocLock" />
    <upsell-mail-forwarding-pay-modal :bus="bus" @completed="paymentCompletedUpsellMailForwarding" />
    <request-document-modal :bus="bus" @completed="documentRequestCompleted" />
    <learn-more-documents-modal ref="learn-more-documents-modal" />
    <view-document-request-modal :bus="bus" />
    <learn-more-button v-if="documentRequestsEnabledOnWebsite" @onclick="learnMore()" />
  </b-container>
</template>

<script>
import FeatherIcon from '@/components/shared/FeatherIcon'
import Masthead from '@/components/shared/Masthead'
import { getDateFilter } from '@/common/modules/dates'
import { formatDateString, notificationWith } from '@/common/modules/formatters'
import Vue from 'vue'
import DocLockModal from '@/components/DocLockModal'
import PayInvoices from '@/components/PayInvoices'
import RequestDocumentModal from '@/components/RequestDocument/RequestModal/RequestDocumentModal'
import DocLockPayModal from '@/components/DocLockPayModal'
import UpsellMailForwardingPayModal from '@/components/UpsellMailForwardingPayModal'
import LearnMoreDocumentsModal from '@/components/LearnMore/LearnMoreDocumentsModal'
import LearnMoreButton from '@/components/shared/LearnMoreButton'
import ViewDocumentRequestModal from '@/components/RequestDocument/ViewModal/ViewDocumentRequestModal'
import _ from 'lodash'
import AjaxTable from '../components/shared/AjaxTable'
import {
  createOrFindClientInteractionLog,
  logClientInteraction,
} from '@/common/modules/clientInteractionLog'
import { mapGetters } from 'vuex'

export default {
  name: 'Documents',
  components: {
    AjaxTable,
    FeatherIcon,
    Masthead,
    DocLockModal,
    RequestDocumentModal,
    PayInvoices,
    DocLockPayModal,
    UpsellMailForwardingPayModal,
    LearnMoreDocumentsModal,
    LearnMoreButton,
    ViewDocumentRequestModal,
  },
  props: {
    requestDocumentId: null,
  },
  data() {
    return {
      dataCount: null,
      dataSharedCount: null,
      tabIndex: 0,
      query: null,
      appliedFilters: [],
      select: null,
      selectAll: false,
      selection: [],
      showModalErrorAlert: false,
      companyUploadSelection: null,
      companies: [],
      viewedDocument: null,
      uploadedDocument: null,
      currentPage: 1,
      documentRequestsEnabledOnWebsite: false,
      bus: new Vue(),
      clientInteractionLog: null,
      filterOptions: {
        radioGroups: [
          { key: 'lock_status', filters: [
              { key: 'lock1', scope: 'by_locked', value: '1', label: 'Locked' },
              { key: 'lock2', scope: 'by_locked', value: '0', label: 'Not Locked' },
            ] },
          { key: 'document_status', filters: [
              { key: 'read1', scope: 'by_status', value: 'read', label: 'Read' },
              { key: 'read2', scope: 'by_status', value: 'unread', label: 'Unread' },
            ] },
        ],
        dateRadioGroup: {
          filters: [
            getDateFilter('PAST_1_WEEK'),
            getDateFilter('PAST_1_MONTH'),
            getDateFilter('PAST_3_MONTHS'),
            getDateFilter('PAST_6_MONTHS'),
          ],
          custom: true,
        },
      },
    }
  },
  computed: {
    ...mapGetters('account', ['getAll']),
    // The documents that are selected and not "payment locked".
    selectedUnlocked() {
      return this.selection.filter(document => !document.payment_locked)
    },
    selectedLocked() {
      return this.selection.filter(document => document.payment_locked)
    },
    selectedCanBeRequested() {
      return this.selection.filter(document => document.document_can_be_requested)
    },
    selectedCanNotBeRequested() {
      return this.selection.filter(document => !document.document_can_be_requested)
    },
    selectedContainsPackageOrOversized() {
      return this.selection.filter(document => document.is_package || document.is_oversized)
    },
    selectedDocsBelongToSameCompany() {
      return this.selection.every(document => document.company_id === this.selection[0].company_id)
    },
    downloadBtnText() {
      if (this.selection.length > 0 && !this.hasSmallScreen) {
        const blocked = this.selectedLocked.length
        const downloadable = this.selectedUnlocked.length
        const suffix = downloadable > 1 || downloadable === 0 ? 's' : ''
        const warning = blocked >= 1 ? `(${blocked} Locked)` : ''
        return `Download ${downloadable} Document${suffix} ${warning}`
      } else {
        return 'Download'
      }
    },
    downloadBtnDisabled() {
      return this.selectedUnlocked.length === 0
    },
    requestMultipleBtnDisabled() {
      return !(this.selectedCanBeRequested.length > 1 && this.selectedCanNotBeRequested.length < 1 && this.selectedLocked.length < 1 && this.selectedContainsPackageOrOversized.length < 1 && this.selectedDocsBelongToSameCompany)
    },
    disabledReason() {
      if (!this.selectedDocsBelongToSameCompany) {
        return 'Bundle Multiple Docs is only available for documents belonging to the same company.'
      } else if (this.selectedContainsPackageOrOversized.length > 0) {
        return 'Bundle Multiple Docs is not available for packages or oversized documents. Please request them individually.'
      }else if (this.selectedCanNotBeRequested.length > 0) {
        return 'One or more selected documents cannot be requested.'
      }else if (this.selection.length <= 1){
        return 'Select two or more eligible documents to request.'
      } else {
        return ''
      }
    },
    myDocuments() {
      return {
        columns: [
          { key: 'company_name', sortable: true, selectable: true },
          {
            key: 'jurisdiction',
            sortable: true,
            sortAs: 'state_province_region',
            selectable: true,
          },
          { key: 'type', sortable: true, selectable: true },
          {
            key: 'approved_at',
            sortable: true,
            selectable: true,
            formatter: value => this.dateTableColumn(value),
          },
          { key: 'status', sortable: true, selectable: true },
          { key: 'action', sortable: false, selectable: true },
          { key: 'requestDocument', sortable: false, selectable: true, visible:false },
        ],
        url: 'client/client_documents',
        parameters: {
          search_name: 'search_documents_page',
          query: this.query,
          filter: this.appliedFilters,
        },
        defaultOrderBy: 'approved_at',
        defaultOrderDirection: 'desc',
      }
    },
    documentsSharedWithMe() {
      return {
        columns: [
          { key: 'company_name', sortable: true, selectable: true },
          {
            key: 'jurisdiction',
            sortable: true,
            sortAs: 'state_province_region',
            selectable: true,
          },
          { key: 'type', sortable: true, selectable: true },
          {
            key: 'approved_at',
            sortable: true,
            selectable: true,
            formatter: value => this.dateTableColumn(value),
          },
          { key: 'status', sortable: true, selectable: true },
          { key: 'action', sortable: false, selectable: true },
        ],
        url: 'client/client_documents/shared',
        parameters: {
          search_name: 'search_documents_page',
          query: this.query,
          filter: this.appliedFilters,
        },
        defaultOrderBy: 'approved_at',
        defaultOrderDirection: 'desc',
      }
    },
    selectData() {
      let result = []

      if (this.companies && this.companies.length > 0) {
        result = this.companies.map(company => {
          return { value: company.id, text: company.name }
        })
      }
      return result.flat()
    },
    confirmDisabled() {
      return !(this.uploadedDocument && this.companyUploadSelection)
    },
    hasSmallScreen() {
      return this.$mq === 'sm'
    },
  },
  beforeCreate() {
    this.urls = this.$store.getters['documents/urls']
  },
  async mounted() {
    await this.$store.dispatch('requestDocument/setDocumentRequestsEnabledOnWebsite')
    this.documentRequestsEnabledOnWebsite = this.$store.getters['requestDocument/documentRequestsEnabledOnWebsite']
    let selectedDocumentId = this.$store.getters['requestDocument/selectedDocumentId']
    let selectedDocumentCompanyId = this.$store.getters['requestDocument/selectedDocumentCompanyId']
    if(selectedDocumentId && selectedDocumentId.id != null){
      await this.request(selectedDocumentId.id, selectedDocumentCompanyId.companyId)
      await this.$store.dispatch('requestDocument/setSelectedDocumentId', { id: null })
      await this.$store.dispatch('requestDocument/setSelectedDocumentCompanyId', { companyId: null })
    }
    this.companies = await this.$store.dispatch('companies/fetchAll')
    this.bus.$on('payInvoices', this.payInvoices)
    this.bus.$on('payDocLock', this.payDocLock)
    this.bus.$on('payUpsellMailForwarding', this.payUpsellMailForwarding)
    while(this.dataCount == null || this.dataSharedCount == null) {
      await new Promise( r => setTimeout(r, 200) )
    }
    this.tabIndex = this.dataCount === 0 && this.dataSharedCount > 0 ? 1:0
    await this.logInteraction('button', 'visit', 'visit-documents-tab', null, this.getAll[0].id)
  },
  methods: {
    dateTableColumn(date) {
      return date === 'Invalid Date' ? '---' : this.formatDate(date)
    },
    selectableFunction(document) {
      return !document.payment_locked
    },
    selectionEvent(selection) {
      this.selection = selection
    },
    isAcceptedCreditCardQuote(item){
      return (item.status === 'accepted' && item.type === 'Credit Card Quote')
    },
    capitalize: input => _.capitalize(input),
    async downloadSelection() {
      if (this.selectedUnlocked.length > 0) {
        let urls = this.urls

        const missingIds = this.selectedUnlocked
          .filter(function(id) {
            return urls[id] === undefined
          })
          .map(document => document.id)
        try {
          const result = await this.$store.dispatch('documents/getDownloadUrls', {
            ids: missingIds,
          })

          if (result.data.url) {
            let interval = 1000
            await Promise.all(result.data.url.map(async (value, index) => {
              await this.logInteraction('button', 'download', 'download-document', this.selection[index].company_id, this.selection[index].id)
              return new Promise((resolve) => {
                setTimeout(() => {
                  window.location = value
                  resolve()
                }, interval * (index + 1))
              })
            }))
          }
        } catch (error) {
          console.error(error)
        } finally {
          await this.$refs.myDocuments.reload()
          await this.$store.dispatch('dashpanel/refreshUnreadDocCount')
        }
      }
    },
    searchChanged(searchObject) {
      this.query = searchObject.query
      this.appliedFilters = searchObject.filters
    },
    async view(item) {
      this.viewedDocument = item
      await this.logInteraction('button', 'view', 'view-document', this.viewedDocument.company_id, this.viewedDocument.id)
      if (item.payment_locked === false) {
        await this.$router.push({ name: 'document', params: { id: item.id } })
        if (!this.isAcceptedCreditCardQuote(item)) {
          await this.$store.dispatch('documents/markRead', { id: item.id })
        }
        await this.$store.dispatch('dashpanel/refreshUnreadDocCount')
      } else {
        this.bus.$emit('open', this.viewedDocument)
      }
    },
    async request(id, companyId) {
      await this.logInteraction('button', 'request', 'request-document', companyId, id)
      const doc = await this.$store.dispatch('documents/fetchId', {
        id: id,
      })
      if (doc.documentCanBeRequested) {
        this.bus.$emit('openDocumentRequest', [doc])
      } else{
        this.$bvToast.toast('Document Unavailable', { title: 'Error', variant: 'danger' })
      }
    },
    async requestSelection() {
      if (this.selection.length > 1) {
        let docs = await Promise.all(this.selection.map(doc => this.$store.dispatch('documents/fetchId', {
          id: doc.id,
        })))

        for (const doc of docs) {
          await this.logInteraction('button', 'request', 'request-document', doc.companyId, doc.id)
        }

        this.bus.$emit('openDocumentRequest', docs)
      }
    },
    async viewRequest(id) {
      let response = await this.$store.dispatch('requestDocument/viewDocumentRequest', id)
      this.bus.$emit('viewDocumentRequest', response.result)
    },
    cancel() {
      this.$refs.documentUpload.hide()
    },
    onLoad(tableData) {
      this.dataCount = tableData.length
    },
    onSharedLoad(tableData) {
      this.dataSharedCount = tableData.length
    },
    resetModal() {
      this.uploadedDocument = null
      this.companyUploadSelection = null
    },
    companySelected(companyId) {
      this.companyUploadSelection = companyId
    },
    async confirmUpload() {
      let response = await this.$store.dispatch('documents/uploadDocument', {
        params: {
          companyId: this.companyUploadSelection,
          document: this.uploadedDocument,
        },
      })

      if (response.success) {
        await this.$store.dispatch('alerts/addAlert', {
          all: [notificationWith('success', 'Your document has been uploaded successfully.')],
        })
        this.$refs.documentUpload.hide()
      } else {
        this.showModalErrorAlert = true
      }
    },
    resetErrorAlert() {
      this.showModalErrorAlert = false
    },
    payInvoices(invoices) {
      this.bus.$emit('showPayInvoices', invoices)
    },
    payDocLock(params) {
      this.bus.$emit('showDockLockPayModal', params)
    },
    payUpsellMailForwarding(params) {
      this.bus.$emit('showUpsellMailForwardingPayModal', params)
    },
    async paymentCompleted() {
      this.$bvModal.hide('payInvoiceModal')
      await this.$router.push({ name: 'document', params: { id: this.viewedDocument.id } })
      await this.$store.dispatch('documents/markRead', { id: this.viewedDocument.id })
    },
    async paymentCompletedDocLock() {
      this.$bvModal.hide('dockLockPayModal')
      this.$bvToast.toast('Document is Successfully Unlocked', {
        title: 'Payment Success',
        variant: 'success',
        solid: true,
        autoHideDelay: 10000,
      })
      await this.$router.push({ name: 'document', params: { id: this.viewedDocument.id } })
      await this.$store.dispatch('documents/markRead', { id: this.viewedDocument.id })
    },
    async paymentCompletedUpsellMailForwarding() {
      this.$bvModal.hide('upsellMailForwardingPayModal')
      await this.$store.dispatch('documents/removeLock', { id: this.viewedDocument.id } )
      this.$bvToast.toast('Mail Forwarding Successfully Added. Document Successfully Unlocked!', {
        title: 'Payment Success',
        variant: 'success',
        solid: true,
      })
      await this.$router.push({ name: 'document', params: { id: this.viewedDocument.id } })
      await this.$store.dispatch('documents/markRead', { id: this.viewedDocument.id })
    },
    async documentRequestCompleted(isPackage) {
      await this.$refs.myDocuments.reload()
      this.$bvModal.hide('requestDocumentModal')
      const alertMessage = isPackage ? 'Your document request is being estimated.' : 'Your document request is currently pending.'
      this.$bvToast.toast(alertMessage, {
        title: 'Success',
        variant: 'success',
        solid: true,
        autoHideDelay: 10000,
      })
    },
    trackShipping (trackingUrl) {
      window.open(trackingUrl, '_blank')
    },
    formatDate: formatDateString,
    learnMore(){
      this.$refs['learn-more-documents-modal'].show()
    },
    async logInteraction(type, action, name, companyId, objectId) {
      try {
        const objectTable = objectId !== this.getAll[0].id ? 'ClientDocument' : 'Account'

        this.clientInteractionLog = await createOrFindClientInteractionLog({
          category: 'documents',
          subCategory: '',
          objectId: objectId,
          objectTable: objectTable,
          companyId: companyId,
        })
        this.clientInteractionLog = await logClientInteraction({
          clientInteractionLogId: this.clientInteractionLog.id,
          interaction: { type, action, name },
        })
      } catch (error) { /* eslint-disable no-empty */
      } /* eslint-enable no-empty */
    },
  },
}
</script>

<style lang="scss">
#documents {
  max-width: 1200px;
}

.modal-title {
  width: 100%;
  display: inline-block;
  font-size: 1.75rem !important;
}

.fixed-btn-group {
  position: fixed;
  width: 100%;
  background-color: white;
  z-index: 1000;
}

.expandable-row {
  padding: 0 !important;
  margin: 0 !important;
}
.track-link{
  color: #007bff !important;
  cursor: pointer;
}
.track-link:hover {
  text-decoration: underline !important;
}

.type-subtitle {
  font-size: 0.75rem;

  span {
    padding: 0 0.175rem;
  }
}

.confirm-btn {
  background-color: #007bff;
  border-color: #007bff;
}

.upload-btn {
  display: none;
}

@media only screen and (max-width: 576px) {
  .action-btn,
  .input-group-prepend,
  .btn-group,
  .btn-secondary {
    width: 100%;
  }

  .action-btn {
    margin: 0 !important;
  }

  .dropdown-toggle {
    margin: 1.5rem 0 1.0rem 0;
  }
}
</style>
