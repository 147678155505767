var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { attrs: { id: "documents" } },
    [
      _c(
        "masthead",
        {
          attrs: {
            title: "Documents",
            "filter-options": _vm.filterOptions,
            "should-display-date-range": true,
          },
          on: { changed: _vm.searchChanged },
        },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-modal",
                  rawName: "v-b-modal.document-upload-modal",
                  modifiers: { "document-upload-modal": true },
                },
              ],
              staticClass: "action-btn upload-btn",
              class: { "btn-shadow mr-3 rounded": !_vm.hasSmallScreen },
              attrs: {
                variant: "primary",
                "aria-label": "Upload Document button",
              },
            },
            [
              _c("feather-icon", { attrs: { type: "upload" } }),
              !_vm.hasSmallScreen
                ? _c("span", [_vm._v("\n        Upload Document\n      ")])
                : _c("span", [_vm._v("\n        Upload\n      ")]),
            ],
            1
          ),
          _c(
            "b-modal",
            {
              ref: "documentUpload",
              attrs: {
                id: "document-upload-modal",
                size: "lg",
                "hide-footer": "",
                centered: "",
              },
              on: { close: _vm.resetModal, hide: _vm.resetModal },
            },
            [
              _c(
                "b-alert",
                {
                  attrs: { variant: "danger", dismissible: "" },
                  on: { dismissed: _vm.resetErrorAlert },
                  model: {
                    value: _vm.showModalErrorAlert,
                    callback: function ($$v) {
                      _vm.showModalErrorAlert = $$v
                    },
                    expression: "showModalErrorAlert",
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      "An error has occurred when attempting to upload your document."
                    ),
                  ]),
                ]
              ),
              _c(
                "b-container",
                [
                  _c("b-row", { staticClass: "justify-content-center" }, [
                    _c("h5", { staticClass: "modal-title" }, [
                      _vm._v("Upload Document"),
                    ]),
                  ]),
                  _c(
                    "b-row",
                    { staticClass: "mb-4" },
                    [
                      _c("b-form-file", {
                        attrs: {
                          accept: ".pdf",
                          "drop-placeholder": "Drag document here...",
                        },
                        model: {
                          value: _vm.uploadedDocument,
                          callback: function ($$v) {
                            _vm.uploadedDocument = $$v
                          },
                          expression: "uploadedDocument",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("b-row", { staticClass: "justify-content-center" }, [
                    _c("span", { staticClass: "mb-2" }, [
                      _vm._v("\n            Select a company\n          "),
                    ]),
                  ]),
                  _c(
                    "b-row",
                    [
                      _c("b-form-select", {
                        attrs: { options: _vm.selectData },
                        on: { change: _vm.companySelected },
                        model: {
                          value: _vm.companyUploadSelection,
                          callback: function ($$v) {
                            _vm.companyUploadSelection = $$v
                          },
                          expression: "companyUploadSelection",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("b-row", [_c("br")]),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "float-right",
                              attrs: { "aria-label": "Cancel button" },
                              on: { click: _vm.cancel },
                            },
                            [_vm._v("\n              Cancel\n            ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "float-right confirm-btn",
                          attrs: {
                            variant: "primary",
                            "aria-label": "Confirm button",
                            disabled: _vm.confirmDisabled,
                          },
                          on: { click: _vm.confirmUpload },
                        },
                        [_vm._v("\n            Confirm\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-button",
            {
              staticClass: "mr-3 action-btn",
              attrs: {
                variant: "primary",
                "aria-label": _vm.downloadBtnText + " button",
                disabled: _vm.downloadBtnDisabled,
              },
              on: { click: _vm.downloadSelection },
            },
            [
              _c("feather-icon", { attrs: { type: "download" } }),
              _vm._v("\n      " + _vm._s(_vm.downloadBtnText) + "\n    "),
            ],
            1
          ),
          _vm.documentRequestsEnabledOnWebsite
            ? _c(
                "b-button",
                {
                  directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
                  staticClass: "float-right action-btn",
                  attrs: {
                    title: _vm.disabledReason,
                    variant: "primary",
                    "aria-label": "Bundle Multiple Docs button",
                    disabled: _vm.requestMultipleBtnDisabled,
                  },
                  on: { click: _vm.requestSelection },
                },
                [_vm._v("\n      Bundle Multiple Docs\n    ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-tabs",
        {
          model: {
            value: _vm.tabIndex,
            callback: function ($$v) {
              _vm.tabIndex = $$v
            },
            expression: "tabIndex",
          },
        },
        [
          _c(
            "b-tab",
            [
              _c("template", { slot: "title" }, [
                _vm._v("\n        My Documents\n      "),
              ]),
              _c("ajax-table", {
                ref: "myDocuments",
                attrs: {
                  "select-mode": "multi",
                  selectable: true,
                  "table-definition": _vm.myDocuments,
                  "selectable-function": _vm.selectableFunction,
                },
                on: {
                  "selection-changed": _vm.selectionEvent,
                  loaded: _vm.onLoad,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "head(approvedAt)",
                      fn: function (data) {
                        return [
                          _c(
                            "span",
                            {
                              attrs: {
                                id: "`t2-${data}-approvedAt`",
                                data: data,
                              },
                            },
                            [_vm._v("Received")]
                          ),
                        ]
                      },
                    },
                    {
                      key: "head(requestDocument)",
                      fn: function (data) {
                        return _vm.documentRequestsEnabledOnWebsite
                          ? [
                              _c(
                                "span",
                                {
                                  attrs: {
                                    id: "`t2-${data}-requestDocument`",
                                    data: data,
                                  },
                                },
                                [_vm._v("Request Document")]
                              ),
                            ]
                          : undefined
                      },
                    },
                    {
                      key: "cell(status)",
                      fn: function (row) {
                        return [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.capitalize(row.item.status)) +
                              "\n        "
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(jurisdiction)",
                      fn: function (data) {
                        return [
                          _vm._v(
                            "\n          " +
                              _vm._s(data.item.jurisdiction) +
                              "\n        "
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(type)",
                      fn: function (data) {
                        return [
                          _vm._v(
                            "\n          " +
                              _vm._s(data.item.type) +
                              "\n          "
                          ),
                          _c(
                            "div",
                            { staticClass: "text-muted type-subtitle" },
                            [
                              _c("span", [
                                _vm._v(_vm._s(data.item?.vehicle_year)),
                              ]),
                              _c("span", [
                                _vm._v(_vm._s(data.item?.vehicle_make)),
                              ]),
                              _c("span", [
                                _vm._v(_vm._s(data.item?.vehicle_model)),
                              ]),
                              _c("span", [
                                _vm._v(_vm._s(data.item?.vin___hin)),
                              ]),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(action)",
                      fn: function (row) {
                        return [
                          _c(
                            "b-button",
                            {
                              staticClass: "mr-1 pt-0",
                              attrs: {
                                variant: "link",
                                "aria-label": row.item.payment_locked
                                  ? "Unlock Document button"
                                  : "View Document button",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.view(row.item)
                                },
                              },
                            },
                            [
                              row.item.payment_locked
                                ? _c("feather-icon", {
                                    staticClass: "doc-lock-icon-margin",
                                    attrs: {
                                      type: "lock",
                                      width: "18",
                                      height: "18",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    row.item.payment_locked ? "Unlock" : "View"
                                  ) +
                                  "\n          "
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(requestDocument)",
                      fn: function (row) {
                        return _vm.documentRequestsEnabledOnWebsite
                          ? [
                              row.item.document_can_be_requested
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "mr-1 pt-0",
                                          attrs: {
                                            variant: "link",
                                            "aria-label":
                                              "Click to Request button",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.request(
                                                row.item.id,
                                                row.item.company_id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              Click to Request\n            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : row.item.document_request_status != ""
                                ? _c("div", [
                                    row.item.document_request_status ==
                                    "pending_payment"
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "mr-1 pt-0",
                                                attrs: {
                                                  variant: "link",
                                                  "aria-label":
                                                    "Pending payment button",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.viewRequest(
                                                      row.item.id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                Pending Payment\n              "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm.capitalize(
                                          row.item.document_request_status
                                        ) == "Completed" &&
                                        row.item.document_tracking_number
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "mr-1 pt-0",
                                                attrs: {
                                                  variant: "link",
                                                  "aria-label":
                                                    _vm.capitalize(
                                                      row.item
                                                        .document_request_status
                                                    ) + " button",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.viewRequest(
                                                      row.item.id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm.capitalize(
                                                        row.item
                                                          .document_request_status
                                                      )
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "inline-flex",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                -\n                "
                                                ),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "ml-1 track-link",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.trackShipping(
                                                          row.item
                                                            .document_tracking_url
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  Track\n                "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : row.item.document_request_status ==
                                        "rejected"
                                      ? _c("div", [
                                          _vm._v(
                                            "\n              Document Unavailable\n            "
                                          ),
                                        ])
                                      : _c(
                                          "div",
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "mr-1 pt-0",
                                                attrs: {
                                                  variant: "link",
                                                  "aria-label":
                                                    _vm.capitalize(
                                                      row.item
                                                        .document_request_status
                                                    ) + " button",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.viewRequest(
                                                      row.item.id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm.capitalize(
                                                        row.item
                                                          .document_request_status
                                                      )
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                  ])
                                : _vm._e(),
                            ]
                          : undefined
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            2
          ),
          _c(
            "b-tab",
            [
              _c("template", { slot: "title" }, [
                _vm._v("\n        Documents Shared With Me\n      "),
              ]),
              _c("ajax-table", {
                attrs: {
                  "select-mode": "multi",
                  selectable: true,
                  "table-definition": _vm.documentsSharedWithMe,
                },
                on: {
                  "selection-changed": _vm.selectionEvent,
                  loaded: _vm.onSharedLoad,
                },
                scopedSlots: _vm._u([
                  {
                    key: "head(approvedAt)",
                    fn: function (data) {
                      return [
                        _c(
                          "span",
                          { attrs: { id: "`${data}-approvedAt`", data: data } },
                          [_vm._v("Received")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(status)",
                    fn: function (row) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.capitalize(row.item.status)) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(jurisdiction)",
                    fn: function (data) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(data.item.jurisdiction) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(type)",
                    fn: function (data) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(data.item.type) +
                            "\n          "
                        ),
                        _c("div", { staticClass: "text-muted type-subtitle" }, [
                          _c("span", [_vm._v(_vm._s(data.item?.vehicle_year))]),
                          _c("span", [_vm._v(_vm._s(data.item?.vehicle_make))]),
                          _c("span", [
                            _vm._v(_vm._s(data.item?.vehicle_model)),
                          ]),
                          _c("span", [_vm._v(_vm._s(data.item?.vin___hin))]),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "cell(action)",
                    fn: function (row) {
                      return [
                        _c(
                          "b-button",
                          {
                            staticClass: "mr-1 pt-0",
                            attrs: {
                              variant: "link",
                              "aria-label": row.item.payment_locked
                                ? "Unlock Document button"
                                : "View Document button",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.view(row.item)
                              },
                            },
                          },
                          [
                            row.item.payment_locked
                              ? _c("feather-icon", {
                                  staticClass: "doc-lock-icon-margin",
                                  attrs: {
                                    type: "lock",
                                    width: "18",
                                    height: "18",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  row.item.payment_locked ? "Unlock" : "View"
                                ) +
                                "\n          "
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("doc-lock-modal", {
        attrs: { document: _vm.viewedDocument, bus: _vm.bus },
      }),
      _c("pay-invoices", {
        attrs: { bus: _vm.bus },
        on: { completed: _vm.paymentCompleted },
      }),
      _c("doc-lock-pay-modal", {
        attrs: { bus: _vm.bus },
        on: { completed: _vm.paymentCompletedDocLock },
      }),
      _c("upsell-mail-forwarding-pay-modal", {
        attrs: { bus: _vm.bus },
        on: { completed: _vm.paymentCompletedUpsellMailForwarding },
      }),
      _c("request-document-modal", {
        attrs: { bus: _vm.bus },
        on: { completed: _vm.documentRequestCompleted },
      }),
      _c("learn-more-documents-modal", { ref: "learn-more-documents-modal" }),
      _c("view-document-request-modal", { attrs: { bus: _vm.bus } }),
      _vm.documentRequestsEnabledOnWebsite
        ? _c("learn-more-button", {
            on: {
              onclick: function ($event) {
                return _vm.learnMore()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }